// Used as suggested by django documentation
function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const csrftoken = getCookie('csrftoken');


window.cta_request_call = (location) => {
  $('#phone-number-error-label').addClass('d-none');
  const phone_number = $(`#cta-input-${location}`).val();

  if (phone_number.length < 7) {
    $('#phone-number-error-label').removeClass('d-none');
    return;
  }

  $(`#submit-${location}`).prop('disabled', true);

  $.post({
    url: 'request-call/',
    data: {'phone_number': phone_number},
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRFToken', csrftoken);
    },
    success: function () {
      $(`#submit-${location}`).prop('disabled', false);
      showSuccessToast(location);
    },
    error: function (xhr, ajaxOptions, thrownError) {
      $(`#submit-${location}`).prop('disabled', false);
      showErrorToast(location);
    }
  });
}

function showSuccessToast(location) {
  hideAllToasts();
  showToast(`#toast-success-${location}`);
}

function showErrorToast(location) {
  hideAllToasts();
  showToast(`#toast-error-${location}`);
}

function showToast(id) {
  $(id).show();
  $(id).toast('show');
}

function hideAllToasts() {
  $('.toast').hide();
  $('.toast').toast('hide');
}
